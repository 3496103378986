var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style.assortments},[_c('div',{class:_vm.$style.wrapper},[_c('div',{class:_vm.$style.search},[_c('Autocomplete',{attrs:{"search":_vm.search,"placeholder":"Поиск по названию","valueNames":['name']},on:{"querySearch":_vm.querySearch,"selectItem":_vm.handleSelectAssortment,"handleFindItems":_vm.handleFindAssortments,"input":function($event){_vm.search = $event}}}),_c('el-button',{class:_vm.$style.button,attrs:{"type":"primary"},on:{"click":_vm.getList}},[_vm._v(" Показать ")])],1),_c('el-cascader',{staticStyle:{"width":"30rem"},attrs:{"options":_vm.categories,"props":{
        label: 'name',
        value: 'id',
        checkStrictly: true,
        emitPath: false,
      },"collapse-tags":"","filterable":"","clearable":"","placeholder":"Поиск по категории"},on:{"change":_vm.getList},model:{value:(_vm.categoryId),callback:function ($$v) {_vm.categoryId=$$v},expression:"categoryId"}}),_c('div',{class:_vm.$style.checkBoxWrapper},[_c('label',[_vm._v("Рекламные")]),_c('el-checkbox',{attrs:{"checked":_vm.isPromotional},on:{"change":function($event){;(_vm.isPromotional = $event), _vm.getList()}}})],1),_c('div',{class:_vm.$style.checkBoxWrapper},[_c('label',[_vm._v("Активные")]),_c('el-checkbox',{attrs:{"checked":_vm.isActive},on:{"change":function($event){;(_vm.isActive = $event), _vm.getList()}}})],1),_c('div',{class:_vm.$style.checkBoxWrapper},[_c('label',[_vm._v("В каталоге")]),_c('el-checkbox',{attrs:{"checked":_vm.isInCatalog},on:{"change":function($event){;(_vm.isInCatalog = $event), _vm.getList()}}})],1),_c('div',{class:_vm.$style.createButton},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push(
            _vm.$options.ADDWINE_ADMIN_ROUTES.CATALOG.ASSORTMENTS.CREATE,
          )}}},[_vm._v(" Создать ")])],1)],1),_c('el-table',{attrs:{"data":_vm.assortments,"stripe":""}},[_c('el-table-column',{attrs:{"prop":"orderField","label":"Приоритет","width":"120"}}),_c('el-table-column',{attrs:{"label":"Назначен категории","width":"280"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.category)?_c('div',[_c('router-link',{class:_vm.$style.catalogLink,attrs:{"to":_vm.getRoute({
                route:
                  _vm.$options.ADDWINE_ADMIN_ROUTES.CATALOG.CATEGORIES.CATEGORY,
                params: { id: scope.row.category.id },
              })}},[_vm._v(" "+_vm._s(scope.row.category.name)+" ")])],1):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"name","label":"Название","width":"380"}}),_c('el-table-column',{attrs:{"prop":"slug","label":"Имя в URL","width":"380"}}),_c('el-table-column',{attrs:{"label":"Активный"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('input',{class:_vm.$style.checkbox,attrs:{"type":"checkbox"},domProps:{"checked":scope.row.isActive}})]}}])}),_c('el-table-column',{attrs:{"label":"Отображать в каталоге"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('input',{class:_vm.$style.checkbox,attrs:{"type":"checkbox"},domProps:{"checked":scope.row.isInMainCatalog}})]}}])}),_c('el-table-column',{attrs:{"label":"Рекламный"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('input',{class:_vm.$style.checkbox,attrs:{"type":"checkbox"},domProps:{"checked":scope.row.isPromotional}})]}}])}),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{attrs:{"to":_vm.getRoute({
              route:
                _vm.$options.ADDWINE_ADMIN_ROUTES.CATALOG.ASSORTMENTS.CATALOG,
              params: { id: scope.row.id },
            })}},[_vm._v(" Каталог ")])]}}])}),_c('el-table-column',{attrs:{"width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('ActionButtons',{class:_vm.$style.buttons,attrs:{"viewLink":_vm.getRoute({
              route: _vm.$options.ADDWINE_ROUTES.CATALOG.RANGES,
              params: { id: scope.row.slug },
              site: _vm.$options.PROJECTS.ADDWINE,
            }),"edit-link":_vm.getRoute({
              route:
                _vm.$options.ADDWINE_ADMIN_ROUTES.CATALOG.ASSORTMENTS.ASSORTMENT,
              params: { id: scope.row.id },
            })},on:{"delete":function($event){return _vm.remove(scope.row)}}})]}}])})],1),_c('el-pagination',{class:_vm.$style.pagination,attrs:{"layout":"prev, pager, next","page-size":_vm.limit,"total":_vm.total,"current-page":_vm.page,"background":""},on:{"update:currentPage":function($event){_vm.page=$event},"update:current-page":function($event){_vm.page=$event},"current-change":_vm.getList}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }